<template>
  <footer>
    <div class="inner-footer">
      <div class="site-name footer-col">
        <h4>Rajmund Bednorz</h4>
      </div>
      <div class="contact footer-col">
        rajmundr@yahoo.com<br />
        +49 176 43242 900
      </div>
      <div class="projects footer-col">
        <div class="footer-title">Projects</div>
        <ul class="footer-links">
          <li><router-link to="/dress-project">Dress</router-link></li>
          <li><router-link to="/jacket-project">Jacket</router-link></li>
          <li><router-link to="/trousers-project">Trousers</router-link></li>
          <li><router-link to="/skirt-project">Skirt</router-link></li>
          <li><router-link to="/sleeve-project">Sleeve</router-link></li>
          <li>
            <router-link to="/industrial-project">Industrial Cut</router-link>
          </li>
        </ul>
      </div>
      <div class="navigation footer-col">
        <ul>
          <li><router-link to="/about">About</router-link></li>
          <li><a href="/#projects">Projects</a></li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "appFooter",
};
</script>

<style>
footer {
  border-top: 1px solid var(--grey-border);
  position: relative;
  background-color: #fff;
  color: var(--text);
}
.inner-footer {
  display: flex;

  padding: 2rem 0;
}

.footer-col {
  flex: 2;
  color: var(--text);
  font-size: 16px;
}
.footer-col .navigation {
  justify-content: flex-end;
}

.footer-col ul {
  list-style: none;
}
.footer-col li a {
  color: var(--text);
  display: inline-block;
  margin-right: 0.5rem;
  padding: 0;
}
.footer-col li a:hover {
  text-decoration: underline;
}

.footer-col li,
.footer-title {
  font-weight: 600;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
}

.footer-links li {
  font-weight: 300;
}

.footer-col.projects {
  flex: 3;
}

.contact {
  font-weight: 300;
}

.site-name {
  padding: 0 1rem;
}

.site-name a {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 998px) {
  .inner-footer {
    display: flex;
    flex-direction: column;
    text-align: center;

    padding: 2.5rem 0 1rem;
  }

  .footer-col {
    padding: 1rem;
    min-width: 100%;
  }

  .footer-links {
    justify-content: center;
  }
  .footer-col.navigation {
    justify-content: center;
  }

  .footer-col.navigation ul {
    display: block;
  }
  .footer-col.navigation a {
    margin: 0;
  }
  .footer-col li {
    display: block;
  }
}
</style>