<template>
  <div class="bg-wrapper">
    <div class="tile tile1">
      <div ref="div1">
        <img src="../assets/fabric.jpg" alt="" />
      </div>
    </div>
    <div class="tile tile2">
      <div ref="div2">
        <img src="../assets/fabric.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "headerAnim",
  data() {
    return {
      divs: [],
      wrapper: null,
      opac: false,
    };
  },

  mounted() {
    this.divs = this.getDivs();
    this.initAnim();
    this.wrapper = this.$parent.$refs["anim-wrapper"];
  },
  methods: {
    getDivs() {
      return Object.values(this.$refs);
    },
    initAnim() {
      [...this.divs].forEach((item) => {
        gsap.set(item, {
          x: "0%",
        });
        gsap.to(item, {
          scrollTrigger: {
            trigger: this.wrapper,
            scrub: 0.1,
            start: "top bottom",
            end: "top top",
          },
          x: "-30%",
          ease: "none",
        });
      });
    },
  },
};
</script>

<style>
.bg-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}

.bg-wrapper::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-overlay);
}

.tile {
  display: block;
  position: relative;
  flex: 1;
  overflow: hidden;
}

.tile2 {
  transform: scale(-1, 1);
}

.tile > div {
  display: block;
  width: 160%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.tile > div > img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}
</style>