<template>
  <div class="home">
    <div class="hero animation-hero">
      <div class="hero-image" :class="!pageTransition && 'animating'">
        <header-anim></header-anim>
      </div>
      <div class="hero-text">
        <h1>Rajmund Bednorz</h1>
        <h3>Fashion Pattern Cutter</h3>
      </div>
    </div>
    <div class="animation-wrapper" ref="anim-wrapper">
      <div class="container">
        <div class="project-header">
          <h2 ref="rtitle" class="anim-text" id="projects">Projects</h2>
        </div>
        <div class="wrapper">
          <div class="image-grid">
            <div class="image-wrapper image-1" ref="i1">
              <img src="../assets/WA0000.jpg" alt="" />
              <router-link to="/dress-project" class="project-link">
                <span class="project-title"><i>Dress Project</i> </span>
              </router-link>
            </div>
            <div class="image-wrapper image-2" ref="i2">
              <img src="../assets/jacket/jacket-front-update.jpg" alt="" />
              <router-link to="/jacket-project" class="project-link">
                <span class="project-title"><i>Jacket Project</i> </span>
              </router-link>
            </div>
            <div class="image-wrapper image-3" ref="i3">
              <img src="../assets/parallax-img/p-5.jpg" alt="" />
              <router-link to="/trousers-project" class="project-link">
                <span class="project-title"><i>Trousers Project</i></span>
              </router-link>
            </div>
            <div class="image-wrapper image-4" ref="i4">
              <img src="../assets/industrial-cut.jpg" alt="" />
              <router-link to="/industrial-project" class="project-link">
                <span class="project-title"><i>Industrial Cut</i></span>
              </router-link>
            </div>
            <div class="image-wrapper image-5" ref="i5">
              <img src="../assets/sleeve/sleeve.jpg" alt="" />
              <router-link to="/sleeve-project" class="project-link">
                <span class="project-title"
                  ><i>Sleeve Project</i></span
                ></router-link
              >
            </div>
            <div class="image-wrapper image-6" ref="i6">
              <img src="../assets/skirt/skirt-front.jpg" alt="" />
              <router-link to="/skirt-project" class="project-link">
                <span class="project-title"><i>Skirt Project</i></span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import HeaderAnim from "../components/HeaderAnim.vue";

gsap.registerPlugin(ScrollTrigger);

export default {
  components: { HeaderAnim },
  name: "Home",
  data() {
    return {
      pics: [],
      wrappers: [],
      delays: [200, 0, 100, -150, -150, -250],
      killed: false,
      linkColor: "black",
      pageTransition: true,
    };
  },

  mounted() {
    this.refs = this.getRefs();

    this.wrappers = this.refs.filter((item) =>
      item.className.includes("image-wrapper")
    );
    this.pics = this.getPics();
    this.title = this.refs.find((item) => item.nodeName === "H2");
    this.heroBG = this.refs.find((item) => item.nodeName === "IMG");
    this.titles = this.getTitles();
    this.initScrollAnim();

    //waiting half second page transition
    const self = this;
    setTimeout(() => {
      self.pageTransition = false;
    }, 500);
  },

  methods: {
    getRefs() {
      return Object.values(this.$refs);
    },
    getPics() {
      return this.wrappers.map((item) => item.querySelector("img"));
    },
    getTitles() {
      return this.wrappers.map((item) => item.querySelector("i"));
    },

    createAnim(pic, i, mobile) {
      gsap.set(pic, {
        scale: 1.2,
      });

      let className = `.image-${i + 1}`;

      gsap.to(pic, {
        scrollTrigger: {
          trigger: className,
          scrub: true,
          start: "top bottom",
          end: "bottom top",
        },
        scale: 1,
        ease: "Power3.out",
      });
      // parrallax anim different params inside delays[]
      if (mobile) return;
      gsap.to(this.wrappers[i], {
        scrollTrigger: {
          trigger: className,
          scrub: 0,
          start: "top bottom",
          end: "bottom top",
        },
        y: this.delays[i],
        ease: "none",
      });
    },

    initScrollAnim() {
      let self = this;
      ScrollTrigger.matchMedia({
        "(min-width:576px)": function () {
          if (!self.killed) {
            // ONLY IF NOT KILLED!
            self.pics.forEach((image, index) => {
              self.createAnim(image, index);
            });
          }
        },
        "(max-width:575px)": function () {
          if (!self.killed) {
            self.pics.forEach((image, index) => {
              self.createAnim(image, index, true);
            });
          }
        },
      });
    },
  },

  beforeDestroy() {
    // clean up scrolltrigger once page transition is over
    const self = this;
    setTimeout(() => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
      self.killed = true;
    }, 500);
  },
};
</script>
<style >
.hero {
  position: relative;
  display: block;
}

.hero-image {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: #fff;
}
.hero-image.animating {
  background-color: var(--text);
}

.hero-image img {
  object-position: bottom left;
  object-fit: cover;
}

.hero-image img {
  object-position: bottom left;
  object-fit: cover;
}

.hero-text {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  color: white;
  padding-left: 1rem;
  width: 100%;
}
.hero-text h1 {
  font-weight: 800;
}

.hero-text h3 {
  color: white;
}

.right img {
  object-position: right center;
}

.animation-wrapper {
  margin-top: 100vh;
  background-color: #fff;
  display: block;
  position: relative;
}

.animation-hero {
  position: fixed;
  top: 0;
  z-index: 0;
}

main {
  background-color: #fff;
  position: relative;
}

.project-header {
  padding-top: 2rem;
}

.wrapper {
  max-width: 80vw;
  display: flex;
  overflow: hidden;
  margin: 0 auto;
}

.image-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 350px;
  grid-gap: 20px;
  margin: 2rem auto 2rem;
}

.image-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
  background-color: #fff;
}

.image-wrapper {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.project-link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  transition: 0.35s ease-in-out;
}

.project-link:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.project-link i::after {
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 1.2s cubic-bezier(0.16, 1, 0.3, 1);
  width: 100%;
  background: var(--text);
}
.project-link:hover i::after {
  transform: scaleX(1);
  transform-origin: left;
}
.project-link:active i::after {
  height: 2px;
}

.image-grid:focus-within .project-link::before,
.image-grid:focus-within .project-title,
.image-grid:focus-within .project-link:hover i:after {
  color: transparent;
  background-color: transparent;
  box-shadow: 0px -1px 0px transparent inset;
  transition: all 0.1s ease-out;
}

.project-link::before {
  content: "";
  display: inline-block;
  background-color: var(--text);
  width: 0.4rem;
  height: 2rem;
  margin-right: 1rem;
}

.project-title {
  display: inline-block;
  position: absolute;
  top: 0.5rem;
  overflow: hidden;
  font-weight: 600;
  letter-spacing: 0.05rem;
  font-size: 1rem;
  color: var(--text);
}

.project-title i {
  display: inline-block;
  position: relative;
  font-style: normal;
  box-shadow: 0px 0px 0px transparent inset;
  transition: box-shadow 0.35s ease-in-out;
}

@media screen and (min-width: 576px) {
  .image-grid {
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(27, 1fr);
    margin: 10vh auto -10vh;
  }

  .image-1 {
    grid-column: 1 / 6;
    grid-row: 1 / 8;
  }

  .image-2 {
    grid-column: 9 / 13;
    grid-row: 5 / 11;
  }

  .image-3 {
    grid-column: 5 / 10;
    grid-row: 9 / 16;
  }

  .image-4 {
    grid-column: 4 / 7;
    grid-row: 16 / 19;
  }

  .image-5 {
    grid-column: 9/13;
    grid-row: 19/26;
  }

  .image-6 {
    grid-column: 1 / 6;
    grid-row: 20 / 27;
  }

  .wrapper {
    max-width: 540px;
    height: calc(540px * 1.8);
  }

  .image-grid {
    margin: 1rem auto -4rem;
  }
}

@media screen and (min-width: 768px) {
  .wrapper {
    max-width: 720px;
    height: calc(720px * 1.8);
  }
}

@media screen and (min-width: 992px) {
  .wrapper {
    max-width: 850px;
    height: calc(850px * 1.8);
  }
}

@media screen and (min-width: 1200px) {
  .wrapper {
    max-width: 1140px;
    height: calc(1140px * 1.8);
  }
}

@media screen and (min-width: 1400px) {
  .wrapper {
    max-width: 1200px;
    height: calc(1200px * 1.8);
  }
}
</style>
