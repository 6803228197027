<template>
  <div id="app">
    <nav-bar :loc="currentPage">
      <template v-slot:logo>
        <router-link to="/" class="menu-link">Rajmund Bednorz</router-link>
      </template>

      <ul>
        <li>
          <router-link
            :to="currentPage !== 'home' ? '/#projects' : '#projects'"
            class="menu-link"
            >Projects</router-link
          >
        </li>
        <li><router-link to="/about" class="menu-link">About</router-link></li>
      </ul>
    </nav-bar>

    <transition name="page-fade" mode="out-in" appear>
      <router-view />
    </transition>

    <app-footer></app-footer>
    <div id="preload">
      <img src="./assets/fabric.jpg" />
      <img src="./assets/subpage-bg-1.jpg" alt="" />
      <img src="./assets/subpage-bg-2.jpg" alt="" />
      <img src="./assets/subpage-bg-3.jpg" alt="" />
      <img src="./assets/subpage-bg-4.jpg" alt="" />
      <img src="./assets/subpage-bg-5.jpg" alt="" />
      <img src="./assets/subpage-bg-6.jpg" alt="" />
      <img src="./assets/bg-about.jpg" alt="" />
      <img src="./assets/WA0000.jpg" alt="" />
      <img src="./assets/jacket/jacket-front-update.jpg" alt="" />
      <img src="./assets/parallax-img/p-5.jpg" alt="" />
      <img src="./assets/industrial-cut.jpg" alt="" />
      <img src="./assets/sleeve/sleeve.jpg" alt="" />
      <img src="./assets/skirt/skirt-front.jpg" alt="" />
    </div>
  </div>
</template>
<script>
import navBar from "@/components/NavBar";
import appFooter from "@/components/AppFooter";

export default {
  name: "App",
  components: {
    navBar,
    appFooter,
  },

  data() {
    return {
      currentPage: "home",
    };
  },
  watch: {
    $route(to) {
      const name = to.name.includes("-") ? to.name.split("-")[1] : to.name;

      this.currentPage = name.toLowerCase();
    },
  },
};
</script>

<style>
@import "./css/variables.css";
* {
  font-family: "Nunito Sans", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

html,
.root {
  line-height: 1.5;
  font-size: 16px;
}

@media screen and (min-width: 1400px) {
  html,
  .root {
    font-size: 18px;
  }
}
@media screen and (min-width: 1600px) {
  html,
  .root {
    font-size: 22px;
  }
}

h1 {
  font-size: 4.5em;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 1;
}

h2 {
  text-align: right;
  font-size: 4.5em;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--primary-color);
  letter-spacing: 0.05rem;
}

h3 {
  font-size: 1.5em;
  letter-spacing: 0.04rem;
  font-weight: 300;
  color: var(--text);
}

h4 {
  font-size: 1.2em;
  letter-spacing: 0.1rem;
  font-weight: 800;
  color: var(--text);
}

.text-block {
  font-weight: 300;
  color: var(--text);
  line-height: 1.5;
  font-size: 1.25rem;
  letter-spacing: -0.01rem;
  margin-bottom: 1rem;
}

.text-block p {
  margin: 1rem 0;
}

.text-block ul {
  list-style: none;
}
.text-block li:before {
  content: "• ";
}

.intro-letter {
  margin-right: 0.5rem;
  margin-bottom: -0.5rem;
  float: left;
  font-size: 3.95rem;
  line-height: 1;
  font-weight: 200;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: top;
}

a {
  text-decoration: none;
}

.sub .hero-image {
  height: 75vh;
}

.animation-hero {
  position: fixed;
  top: 0;
  z-index: 0;
}

.animation-wrapper {
  margin-top: 100vh;
  background-color: #fff;
  display: block;
  position: relative;
}

.sub .animation-wrapper {
  margin-top: 75vh;
}

.sub .hero-text h1 {
  font-size: 4rem;
  line-height: 1.5;
  font-weight: 600;
  grid-column: 6 / 13;
}

.sub .hero-image img {
  object-position: left top;
  object-fit: cover;
}

.page-grid {
  display: grid;
  max-width: 80vw;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 16px;
  grid-auto-rows: min-content;
  grid-row-gap: 1rem;
  margin: 0 auto;
  padding: 3rem 0;
}

@media screen and (min-width: 769px) {
  .page-grid {
    padding: 4rem 0;
  }
}

.container {
  max-width: 80vw;
  height: auto;
  position: relative;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media screen and (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media screen and (min-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}

.text-block,
.text-content {
  grid-column: 1 / 13;
}
.text-block.full,
.text-content.full {
  grid-column: 1 / 13;
}
.text-block.aside {
  grid-column: 1 / 13;
}

@media screen and (min-width: 769px) {
  .text-block,
  .text-content {
    grid-column: 6 / 12;
  }
  .text-block.aside {
    grid-column: 1 / 5;
  }
}

.text-block p:first-child {
  margin-top: 0;
}

.text-block p:last-child {
  margin-bottom: 0;
}

.image-block {
  display: grid;
  grid-column: 1 / 13;
  grid-auto-rows: min-content;
}

.image-block.large {
  grid-column: 1 / 13;
  grid-auto-rows: min-content;
}
.image-block.jumbo {
  grid-column: 1 / 13;
  grid-auto-rows: min-content;
}
.image-block.landscape {
  grid-column: 1 / 13;
  grid-auto-rows: min-content;
}

.image-block.twin {
  grid-column: 1 / 13;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.5rem;
}

@media screen and (min-width: 769px) {
  .image-block {
    display: grid;
    grid-column: 6 / 10;
    grid-auto-rows: min-content;
  }

  .image-block.large {
    grid-column: 6 / 11;
    grid-auto-rows: min-content;
  }
  .image-block.jumbo {
    grid-column: 4 / 12;
    grid-auto-rows: min-content;
  }
  .image-block.landscape {
    grid-column: 4 / 12;
  }

  .image-block.twin {
    grid-column: 4 / 12;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.5rem;
  }
}

.image-block img {
  object-fit: cover;
  object-position: center center;
}

.inner-image {
  display: flex;
}

.caption {
  font-size: 80%;
  font-style: italic;
  line-height: 1.5;
}

blockquote {
  grid-column: 1 / 12;
  display: flex;
  flex-direction: column;
}

blockquote {
  font-size: 2rem;
  color: var(--text);
  font-weight: 300;
  line-height: 1.25;
}

blockquote small {
  font-size: 50%;
  line-height: 2;
  font-weight: 500;
}

@media screen and (min-width: 769px) {
  blockquote {
    grid-column: 4 / 12;
  }
}

.page-fade-enter-active,
.page-fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease-out;
}

.page-fade-enter,
.page-fade-leave-active {
  opacity: 0;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 3em;
  }

  .sub .hero-text h1 {
    font-size: 3em;
  }

  .sub .hero-text .page-grid {
    display: block;
  }

  h3 {
    font-size: 1.5em;
  }

  h2 {
    text-align: center;
    font-size: 2.5em;
  }

  .hero-text {
    padding: 1rem;
  }

  .footer-links {
    justify-content: center;
  }
}
@media screen and (max-width: 380px) {
  .sub .hero-text h1 {
    font-size: 2em;
  }
}

#preload {
  display: none;
}
</style>
