<template>
  <nav class="navigation" :class="loc">
    <transition name="appear" mode="out-in" appear>
      <div class="logo" v-if="loc !== 'home'">
        <slot name="logo"></slot>
      </div>
    </transition>
    <slot></slot>
  </nav>
</template>

<script>
export default {
  name: "navBar",
  props: ["loc"],
};
</script>
<style>
nav {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  z-index: 1;
}

nav a {
  text-decoration: none;
}
.navigation {
  min-height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navigation ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  padding-right: 1rem;
}

.navigation li {
  padding: 0 1rem;
  font-weight: 600;
  color: #fff;
}

.logo {
  margin-right: auto;
  margin-left: 1rem;
  font-size: 1.25rem;
  letter-spacing: 0.1rem;
  font-weight: 800;
}

.menu-link {
  color: #fff;
}

.logo .menu-link {
  color: #fff;
}

.appear-enter-active,
.appear-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease-in;
}

.appear-enter,
.appear-leave-active {
  opacity: 0;
}

@media screen and (max-width: 576px) {
  .logo {
    font-size: 1rem;
  }
}
</style>
